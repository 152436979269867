<template>
  <div>
    <div class="files">
      <div class="button" v-if="pdf.length > 0" @click="showDocuments = !showDocuments">
        Показать файлы
      </div>
      <div class="pdf" v-if="showDocuments">
        <div class="document" v-for="(doc, index) in pdf" :key="index">
<!--          <iframe-->
<!--            v-if="doc.extension === 'pdf'"-->
<!--            :src="'data:' + doc.type + ';base64,' + doc.content"-->
<!--            height="500px"-->
<!--            width="70%"-->
<!--          ></iframe>-->
            <iframe
                :src="'data:' + doc.type + ';base64,' + doc.content"
                height="500px"
                width="70%"
            ></iframe>
        </div>
      </div>
      <b-overlay :show="isLoadingImg" rounded="lg">
        <div class="image">
          <div class="button" @click="generateImage">Создать изображение</div>
          <div id="img" class="image__generate"></div>
        </div>
      </b-overlay>
    </div>
    <div class="htmlPreview" v-html="html"></div>
    <b-overlay :show="isLoading" rounded="lg" v-if="query.with_preview">
      <div class="send__container">
        <div class="button" @click="send()">Отправить письмо</div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    html: String,
    query: Object,
    pdf: Array,
  },
  data() {
    return {
      isLoading: false,
      isLoadingImg: false,
      showDocuments: false,
    };
  },
  methods: {
    toast(toaster, status, message = "") {
      this.$bvToast.toast(message, {
        title: `Результат`,
        toaster: toaster,
        position: "center",
        autoHideDelay: 10000,
        solid: true,
        appendToast: false,
        variant: status == "ok" ? "success" : "danger",
      });
    },
    send() {
      this.isLoading = true;
      Object.cl;
      let withoutPreview = Object.assign({}, this.query);
      withoutPreview.with_preview = 0;

      axios
        .post("/api", withoutPreview, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.toast("b-toaster-top-full", response.data.status, response.data.message);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async generateImage() {
      this.isLoadingImg = true;
      const el = document.getElementById("downloadImg");
      const options = {
        allowTaint: true,
        imageTimeout: 0,
      };
      const canvasImage = await this.$html2canvas(el, options);
      const generateImage = document.getElementById("img");
      generateImage.innerHTML = "";
      generateImage.append(canvasImage);
      generateImage.querySelector("canvas").style.cssText =
        "width: 200px; height: 400px; border: 2px solid black;";
      this.isLoadingImg = false;
    },
  },
};
</script>

<style scoped>
.pdf {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.document {
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}
.htmlPreview {
  margin-bottom: 120px;
}

.files {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  flex-direction: column;
  align-items: center;
}
.image {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.button {
  display: flex;
  height: 20px;
  width: 660px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #c0976b;
  color: #fff;
  border: 1px solid black;
  cursor: pointer;
  margin: 20px 0;
}
.image .image__generate {
  margin: 27px 0;
}
.button:hover {
  background: #e49c45;
}

.send__container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>
