/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import VueHtml2Canvas from 'vue-html2canvas';
 
Vue.use(VueHtml2Canvas);

import "@babel/polyfill";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
// Vue.component('editor-component', require('./components/EditorComponent.vue').default);




// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('type-form', require('./components/TypeFormComponent.vue').default);
// Vue.component('create-block', require('./components/ModalCreateBlockComponent.vue').default);

// Vue.component('test-component', require('./components/TestComponent.vue').default);

Vue.component('block-editor', require('./components/BlockEditorComponent.vue').default);

Vue.component('block-list', require('./components/MailListBlockComponent.vue').default);

Vue.component('blocks-add', require('./components/BlocksComponent.vue').default);

Vue.component('preview', require('./components/PreviewComponent.vue').default);

window.domtoimage = require('dom-to-image');
window.html2canvas = require('html2canvas');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});




