<template>
  <div>
    <b-alert
      :show="alertDown"
      dismissible
      :variant="alertStyle"
      @dismissed="alertDown = 0"
      @dismiss-count-down="alertLive"
    >
      <p>{{ alertMessage }}</p>
      <b-progress
        :variant="alertStyle"
        :max="alertTime"
        :value="alertDown"
        height="10px"
      ></b-progress>
    </b-alert>
    <div class="form-group">
      <label for="name">Название блока</label>
      <input
        type="text"
        class="form-control"
        id="nameBlock"
        placeholder="Менеджер"
        required
        autocomplete="off"
        v-model="name"
      />
    </div>
    <div class="form-group">
      <label for="section">Название секции</label>
      <input
        type="text"
        class="form-control"
        id="section"
        placeholder="manager / payment_add"
        required
        v-model="section"
        autocomplete="off"
      />
    </div>
    <div class="form-group">
      <label for="blockID">Тип блока</label>
      <select class="form-control" id="blockID" ref="blockID" v-model="typeID">
        <option v-for="(type, index) in types" :key="index" :value="type.id">
          {{ type.name }}
        </option>
      </select>
    </div>
    <div class="form-row">
      <div class="col-md-12"></div>
    </div>
    <!-- button add block and popover with select need template  -->
    <b-button id="show-blocks">Добавить блок</b-button>
    <button
      id="getHtml"
      type="submit"
      class="btn btn-success float-right"
      @click="getHTML"
    >
      Сохранить блок
    </button>
    <b-popover ref="popover" target="show-blocks" triggers="click" placement="topright">
      <div class="row col-12 mx-0 py-2 px-0 bg-gray6">
        <div class="col-12 mb-2">
          <div class="col-12 text-center bg-gray1 text-white py-1">Блоки</div>
        </div>
        <div
          class="col-6 mt-3"
          v-for="(block, index) in blocks"
          v-html="block.short"
          :key="index"
          @click="selectBlock(block.name)"
        >
          <!-- <div v-html="block.short"></div> -->
        </div>
      </div>
    </b-popover>
    <!-- end select template  -->
    <!-- modal for default blocks -->
    <b-modal
      ref="container"
      id="modal-center"
      centered
      class="bg-gray6"
      size="lg"
      @hidden="closeEvent"
    >
      <div class="row justify-content-center mx-0">
        <form id="el-data" class="col-9 text-white">
          <div
            class="col-12 row justify-content-center mx-0 mb-3 text-center bg-white p-3 text-body"
            id="mail-block-schema"
          >
            <div
              v-for="(container, index) in blockContainer"
              :key="index"
              :class="container.main"
            >
              <!-- :class="activeFirst(test.html, test.el)" -->
              <div
                ref="toogleContainer"
                v-for="(block, blockID) in container.content"
                :class="[block.html, block.el === 'el1' ? 'active' : '']"
                :key="blockID"
                v-html="block.value"
                :data-el="block.el"
                @click="insertData(block.el)"
              ></div>
              <!-- @click="dynamic(test.el)" -->
            </div>
          </div>
          <div v-if="blockData && blockName != 'collage'">
            <div class="form-group" v-if="blockName == 'spacer'">
              <label for="input-indent">Отступ</label
              ><input
                type="text"
                class="form-control"
                name="indent"
                id="input-indent"
                placeholder="px"
                v-model="blockData[blockID].spacer"
              />
            </div>
            <div class="form-group" v-else-if="blockName == 'custom'">
              <label :for="inputs.custom.label">HTML code</label>
              <textarea
                :name="inputs.custom.input.name"
                :id="inputs.custom.input.id"
                :rows="inputs.custom.input.rows"
                :class="inputs.custom.input.class"
                v-model="blockData[blockID].custom"
              ></textarea>
            </div>

            <div v-else>
              <div class="row mx-0 px-0 col-12 text-center js-base-el">
                <button
                  type="button"
                  :class="[
                    'col-6 btn rounded-right',
                    blockData[blockID].type == 'banner' ? 'btn-success' : 'btn-gray1',
                  ]"
                  style="border-radius: 0"
                  id="switch-banner"
                  @click="change"
                >
                  Баннер
                </button>
                <button
                  type="button"
                  :class="[
                    'col-6 btn rounded-right',
                    blockData[blockID].type == 'text' ? 'btn-success' : 'btn-gray1',
                  ]"
                  style="border-radius: 0"
                  id="switch-text"
                  @click="change"
                >
                  Текст
                </button>
              </div>
              <!-- add image -->
              <div
                id="block-content"
                class="form-group js-text-el"
                v-if="blockData[blockID].type == 'banner'"
              >
                <div class="form-group form-row mr-0 js-base-el">
                  <label for="input-mail_link" class="col-form-label">Ссылка</label>
                  <div class="col-11">
                    <input
                      type="text"
                      name="link"
                      class="form-control col-auto js-mail-link"
                      id="input-mail_link"
                      v-model="blockData[blockID].link"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-yellow col-1 py-0"
                    data-toggle="modal"
                    data-target="#edit-mail-block-link"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.4353 14.7327L21.5907 15.1337C21.4303 15.6248 21.2316 16.1038 20.9971 16.5652L22.0182
            18.1536C22.2006 18.4374 22.1605 18.8101 21.9221 19.0485L19.0485 21.9221C18.8101 22.1605 18.4374 22.2006
            18.1536 22.0182L16.5652 20.9971C16.1038 21.2316 15.6248 21.4303 15.1337 21.5907L14.7327 23.4353C14.6611
            23.7649 14.3692 24 14.0319 24H9.96808C9.6308 24 9.33893 23.7649 9.26733 23.4353L8.86633 21.5907C8.37524
            21.4303 7.89624 21.2316 7.43481 20.9971L5.84637 22.0182C5.56256 22.2006 5.18994 22.1605 4.95154
            21.9221L2.07788 19.0485C1.83948 18.8101 1.79938 18.4374 1.98175 18.1536L3.00293 16.5652C2.76837 16.1038
            2.5697 15.6248 2.4093 15.1337L0.564697 14.7327C0.235107 14.6609 0 14.3692 0 14.0319V9.96808C0 9.6308
            0.235107 9.33911 0.564697 9.26733L2.4093 8.86633C2.5697 8.37524 2.76837 7.89624 3.00293 7.43481L1.98175
            5.84637C1.79938 5.56256 1.83948 5.18994 2.07788 4.95154L4.95154 2.07788C5.18994 1.83948 5.56256 1.79938
            5.84637 1.98175L7.43481 3.00293C7.89624 2.76837 8.37524 2.5697 8.86633 2.4093L9.26733 0.564697C9.33893
            0.235107 9.6308 0 9.96808 0H14.0319C14.3692 0 14.6611 0.235107 14.7327 0.564697L15.1337 2.4093C15.6248
            2.5697 16.1038 2.76837 16.5652 3.00293L18.1536 1.98175C18.4374 1.79938 18.8101 1.83929 19.0485
            2.07788L21.9221 4.95154C22.1605 5.18994 22.2006 5.56256 22.0182 5.84637L20.9971 7.43481C21.2316 7.89624
            21.4303 8.37524 21.5907 8.86633L23.4353 9.26733C23.7649 9.33911 24 9.6308 24 9.96808V14.0319C24 14.3692
            23.7649 14.6609 23.4353 14.7327ZM16.3028 12C16.3028 9.6275 14.3725 7.6972 12 7.6972C9.6275 7.6972 7.6972
            9.6275 7.6972 12C7.6972 14.3725 9.6275 16.3028 12 16.3028C14.3725 16.3028 16.3028 14.3725 16.3028
            12Z "
                        fill="#464655"
                      />
                    </svg>
                  </button>
                </div>
                <label for="input-mail_alt">Alt</label
                ><input
                  type="text"
                  name="alt"
                  class="form-control js-mail-alt"
                  id="input-mail_alt"
                  v-model="blockData[blockID].alt"
                />
                <!-- <div v-html="inputs.banner"></div>
            <div
              class="form-group form-row mr-0 js-base-el"
              v-html="inputs.common.link"
            ></div> -->
                <!-- <div v-html="inputs.addImg"></div> -->
                <!-- блок с изображением -->
                <div class="row col-12 mx-0 px-0 mt-4 js-banner-el" id="area-el-img">
                  <label class="btn btn-secondary btn-block cursor-pointer">
                    Добавить изображение
                    <input
                      id="block-el-add-img"
                      type="file"
                      accept="image/*"
                      hidden
                      @change="imgUpload()"
                    />
                  </label>
                  <div class="col-6 pl-0">
                    <img
                      id="block-el-img"
                      :src="blockData[blockID].img"
                      style="max-width: 267px; max-height: 250px"
                    />
                  </div>
                  <div class="col-6 pr-0 position-relative" v-if="blockData[blockID].img">
                    <div id="img-name"></div>
                    <label class="btn btn-secondary btn-block cursor-pointer mb-5">
                      Заменить изображение
                      <input
                        id="block-el-edit-img"
                        type="file"
                        accept="image/*"
                        @change="imgUpload()"
                        hidden
                      />
                    </label>
                    <button
                      type="button"
                      class="btn btn-link text-danger pl-0 position-absolute"
                      style="bottom: 0"
                      id="el-del-img"
                    >
                      Удалить изображение
                    </button>
                  </div>
                </div>
              </div>
              <!-- add text -->
              <div id="block-content" class="form-group js-text-el" v-else>
                <label :for="inputs.text.label">Текст</label>
                <textarea
                  :name="inputs.text.input.name"
                  :id="inputs.text.input.id"
                  :rows="inputs.text.input.rows"
                  :class="inputs.text.input.class"
                  v-model="blockData[blockID].text"
                ></textarea>
                <div class="form-group form-row mr-0 js-base-el">
                  <label for="input-mail_link" class="col-form-label">Ссылка</label>
                  <div class="col-11">
                    <input
                      type="text"
                      name="link"
                      class="form-control col-auto js-mail-link"
                      id="input-mail_link"
                      v-model="blockData[blockID].link"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-yellow col-1 py-0"
                    data-toggle="modal"
                    data-target="#edit-mail-block-link"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.4353 14.7327L21.5907 15.1337C21.4303 15.6248 21.2316 16.1038 20.9971 16.5652L22.0182
            18.1536C22.2006 18.4374 22.1605 18.8101 21.9221 19.0485L19.0485 21.9221C18.8101 22.1605 18.4374 22.2006
            18.1536 22.0182L16.5652 20.9971C16.1038 21.2316 15.6248 21.4303 15.1337 21.5907L14.7327 23.4353C14.6611
            23.7649 14.3692 24 14.0319 24H9.96808C9.6308 24 9.33893 23.7649 9.26733 23.4353L8.86633 21.5907C8.37524
            21.4303 7.89624 21.2316 7.43481 20.9971L5.84637 22.0182C5.56256 22.2006 5.18994 22.1605 4.95154
            21.9221L2.07788 19.0485C1.83948 18.8101 1.79938 18.4374 1.98175 18.1536L3.00293 16.5652C2.76837 16.1038
            2.5697 15.6248 2.4093 15.1337L0.564697 14.7327C0.235107 14.6609 0 14.3692 0 14.0319V9.96808C0 9.6308
            0.235107 9.33911 0.564697 9.26733L2.4093 8.86633C2.5697 8.37524 2.76837 7.89624 3.00293 7.43481L1.98175
            5.84637C1.79938 5.56256 1.83948 5.18994 2.07788 4.95154L4.95154 2.07788C5.18994 1.83948 5.56256 1.79938
            5.84637 1.98175L7.43481 3.00293C7.89624 2.76837 8.37524 2.5697 8.86633 2.4093L9.26733 0.564697C9.33893
            0.235107 9.6308 0 9.96808 0H14.0319C14.3692 0 14.6611 0.235107 14.7327 0.564697L15.1337 2.4093C15.6248
            2.5697 16.1038 2.76837 16.5652 3.00293L18.1536 1.98175C18.4374 1.79938 18.8101 1.83929 19.0485
            2.07788L21.9221 4.95154C22.1605 5.18994 22.2006 5.56256 22.0182 5.84637L20.9971 7.43481C21.2316 7.89624
            21.4303 8.37524 21.5907 8.86633L23.4353 9.26733C23.7649 9.33911 24 9.6308 24 9.96808V14.0319C24 14.3692
            23.7649 14.6609 23.4353 14.7327ZM16.3028 12C16.3028 9.6275 14.3725 7.6972 12 7.6972C9.6275 7.6972 7.6972
            9.6275 7.6972 12C7.6972 14.3725 9.6275 16.3028 12 16.3028C14.3725 16.3028 16.3028 14.3725 16.3028
            12Z "
                        fill="#464655"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="btn btn-secondary btn-block">Создать баннер</button> -->
        </form>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            size="md"
            class="float-right"
            @click="createBlock()"
            v-if="!isEdit"
            >Создать</b-button
          >
          <b-button
            variant="success"
            size="md"
            class="float-right"
            @click="saveBlock()"
            v-else
            >Сохранить</b-button
          >
        </div>
      </template>
      <!-- end Modal -->
    </b-modal>
    <!--  end modal for default blocks -->

    <b-modal ref="collage" centered class="bg-gray6" size="lg" @hidden="closeEvent">
      <div class="row justify-content-center mx-0">
        <div class="row justify-content-center mx-0">
          <form id="collage-data" class="col-12 text-white">
            <!-- step 1 -->
            <div
              v-if="collageCurrentStep == 1"
              class="row col-12 mx-0 px-0 mt-4 justify-content-center js-collage-steps"
              id="js-collage-step-1"
            >
              <h2 class="col-12 mb-4 text-center">ШАГ 1</h2>
              <div class="col-12">
                <label class="btn btn-secondary btn-block cursor-pointer">
                  Загрузить шаблон
                  <input
                    id="block-collage-add-html"
                    type="file"
                    accept="text/html"
                    hidden
                    @change="uploadCollagePattern"
                  />
                </label>
              </div>
            </div>
            <!-- step 2 -->
            <div
              class="row col-12 mx-0 px-0 mt-4 justify-content-center js-collage-steps"
              id="js-collage-step-2"
              v-if="collageCurrentStep == 2"
            >
              <h2 class="col-12 mb-4 text-center">ШАГ 2</h2>
              <div class="col-12">
                <label class="btn btn-secondary btn-block cursor-pointer">
                  Загрузить изображения
                  <input
                    id="block-collage-add-images"
                    type="file"
                    multiple="multiple"
                    accept="image/*"
                    hidden
                    @change="uploadCollageImages"
                  /><!-- webkitdirectory="true" // для загрузки содержимого директории-->
                </label>
              </div>
            </div>
            <div
              class="row col-12 mx-0 px-0 mt-4 justify-content-center js-collage-steps"
              id="js-collage-step-3"
              v-if="collageCurrentStep == 3"
            >
              <h2 class="col-12 mb-4 text-center">ШАГ 3</h2>
              <div
                class="col-12 row justify-content-center mx-0 mb-3 text-center bg-white p-3 text-body"
                id="mail-block-schema-collage"
              >
                <table
                  id="________01"
                  width="661"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <tbody>
                    <tr v-for="(row, rowNum) in collagePattern" :key="rowNum">
                      <td
                        v-for="(column, colNum) in row"
                        :key="colNum"
                        :align="column.align"
                        :valign="column.valign"
                        :rowspan="column.rowspan"
                        :colspan="column.colspan"
                      >
                        <a
                          :class="[
                            'btn btn-dark p-0 cursor-pointer js-collage-el',
                            column.el === 'el1' ? 'active' : '',
                          ]"
                          :data-el="column.el"
                          ref="toogleCollage"
                          @click="insertData(column.el)"
                          ><img
                            :src="blockData[column.el].img"
                            :width="column.img.width"
                            :height="column.img.height"
                            alt=""
                            style="display: block"
                            class=""
                        /></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-group form-row mx-0 col-9">
                <div class="col-12 mx-0 px-0">
                  <label class="btn btn-secondary btn-block cursor-pointer">
                    Заменить изображение
                    <input
                      id="block-collage-change-image"
                      type="file"
                      accept="image/*"
                      hidden
                      @change="imgUpload"
                    />
                  </label>
                </div>
                <label for="input-mail_link-collage" class="col-form-label pl-0"
                  >Ссылка</label
                >
                <div class="col-11 pl-0">
                  <input
                    type="text"
                    name="link"
                    class="form-control col-auto js-mail-link"
                    id="input-mail_link-collage"
                    v-model="blockData[blockID].link"
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-yellow col-1 py-0"
                  data-toggle="modal"
                  data-target="#edit-mail-block-link"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.4353 14.7327L21.5907 15.1337C21.4303 15.6248 21.2316 16.1038 20.9971 16.5652L22.0182 18.1536C22.2006 18.4374 22.1605 18.8101 21.9221 19.0485L19.0485 21.9221C18.8101 22.1605 18.4374 22.2006 18.1536 22.0182L16.5652 20.9971C16.1038 21.2316 15.6248 21.4303 15.1337 21.5907L14.7327 23.4353C14.6611 23.7649 14.3692 24 14.0319 24H9.96808C9.6308 24 9.33893 23.7649 9.26733 23.4353L8.86633 21.5907C8.37524 21.4303 7.89624 21.2316 7.43481 20.9971L5.84637 22.0182C5.56256 22.2006 5.18994 22.1605 4.95154 21.9221L2.07788 19.0485C1.83948 18.8101 1.79938 18.4374 1.98175 18.1536L3.00293 16.5652C2.76837 16.1038 2.5697 15.6248 2.4093 15.1337L0.564697 14.7327C0.235107 14.6609 0 14.3692 0 14.0319V9.96808C0 9.6308 0.235107 9.33911 0.564697 9.26733L2.4093 8.86633C2.5697 8.37524 2.76837 7.89624 3.00293 7.43481L1.98175 5.84637C1.79938 5.56256 1.83948 5.18994 2.07788 4.95154L4.95154 2.07788C5.18994 1.83948 5.56256 1.79938 5.84637 1.98175L7.43481 3.00293C7.89624 2.76837 8.37524 2.5697 8.86633 2.4093L9.26733 0.564697C9.33893 0.235107 9.6308 0 9.96808 0H14.0319C14.3692 0 14.6611 0.235107 14.7327 0.564697L15.1337 2.4093C15.6248 2.5697 16.1038 2.76837 16.5652 3.00293L18.1536 1.98175C18.4374 1.79938 18.8101 1.83929 19.0485 2.07788L21.9221 4.95154C22.1605 5.18994 22.2006 5.56256 22.0182 5.84637L20.9971 7.43481C21.2316 7.89624 21.4303 8.37524 21.5907 8.86633L23.4353 9.26733C23.7649 9.33911 24 9.6308 24 9.96808V14.0319C24 14.3692 23.7649 14.6609 23.4353 14.7327ZM16.3028 12C16.3028 9.6275 14.3725 7.6972 12 7.6972C9.6275 7.6972 7.6972 9.6275 7.6972 12C7.6972 14.3725 9.6275 16.3028 12 16.3028C14.3725 16.3028 16.3028 14.3725 16.3028 12Z"
                      fill="#464655"
                    />
                  </svg>
                </button>
              </div>
              <div class="form-group form-row mx-0 col-9">
                <label for="input-mail_alt-collage">Alt</label>
                <input
                  type="text"
                  name="alt"
                  class="form-control js-mail-alt"
                  id="input-mail_alt-collage"
                  v-model="blockData[blockID].alt"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="success"
            size="md"
            class="float-right"
            @click="createBlock()"
            v-if="!isEdit"
            >Создать блок</b-button
          >
          <b-button
            variant="success"
            size="md"
            class="float-right"
            @click="saveBlock()"
            v-else
            >Сохранить</b-button
          >
        </div>
      </template>
      <!-- end Modal -->
    </b-modal>
    <div class="row col-12 px-0 mx-0">
      <div class="col-12" id="mail-code">
        <table
          width="100%"
          border="0"
          cellpadding="0"
          cellspacing="0"
          style="border: none; border-collapse: collapse; border-spacing: 0"
        >
          <tbody>
            <tr>
              <td align="center" valign="top">
                <table
                  width="660"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  style="border: none; border-collapse: collapse; border-spacing: 0"
                >
                  <tbody>
                    <tr>
                      <td height="25"></td>
                    </tr>
                    <tr>
                      <!-- сюда вставляются сгенерированные блоки -->

                      <td align="center" valign="top" id="js-table-wrapper">
                        <draggable v-model="blockList">
                          <div
                            v-for="(item, index) in blockList"
                            :key="index"
                            class="position-relative js-wrap-table-block py-2"
                          >
                            <div
                              class="row m-0 py-2 justify-content-center align-items-center position-absolute edit-table-block"
                            >
                              <div class="col-md-4"></div>
                              <div class="col-md-4 px-0">
                                <button
                                  type="button"
                                  class="btn btn-light col-12 js-edit-created-mail-block"
                                  data-edit-id="6"
                                  @click="editBlock(index)"
                                >
                                  редактировать
                                </button>
                              </div>
                              <div
                                class="col-md-4 row px-0 mx-0 justify-content-end h-100"
                              >
                                <div
                                  class="row justify-content-end px-0 mx-0 col-auto align-self-center"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-type-close js-del-table-block mr-2"
                                    @click="deleteBlock(index)"
                                  ></button>
                                </div>
                              </div>
                            </div>
                            <table
                              width="660"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                border: none;
                                border-collapse: collapse;
                                border-spacing: 0;
                              "
                              class="js-table-block"
                            >
                              <template v-if="item.name == 'custom'"
                                ><tbody v-html="item.html" ref="readyBlock"></tbody
                              ></template>
                              <template v-else>
                                <tbody ref="readyBlock">
                                  <tr v-for="(row, rowNum) in item.html" :key="rowNum">
                                    <td>
                                      <table
                                        style="margin: 0 auto 0 auto; table-layout: fixed"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        width="650"
                                        bgcolor="#ffffff"
                                      >
                                        <tbody>
                                          <!-- Открытие обертки -->
                                          <tr>
                                            <td
                                              v-for="(column, colNum) in row"
                                              :key="colNum"
                                              :width="column.width"
                                              :align="column.align"
                                              :valign="column.valign"
                                              :height="column.height"
                                              :rowspan="column.rowspan"
                                              :colspan="column.colspan"
                                            >
                                              <template v-if="column.img">
                                                <a
                                                  :href="column.link"
                                                  target="_blank"
                                                  style="
                                                    font-family: Roboto, Verdana;
                                                    font-size: 16px;
                                                    color: #c0976b;
                                                  "
                                                >
                                                  <img
                                                    :src="column.value"
                                                    :width="column.img.width"
                                                    :height="column.img.height"
                                                    :alt="column.alt"
                                                    title=""
                                                    style="
                                                      display: block;
                                                      max-width: 100%;
                                                    "
                                                  />
                                                </a>
                                              </template>
                                              <template v-else>
                                                <template v-if="column.link">
                                                  <a
                                                    :href="column.link"
                                                    target="_blank"
                                                    style="
                                                      font-family: Roboto, Verdana;
                                                      font-size: 16px;
                                                      color: #c0976b;
                                                    "
                                                  >
                                                    {{ column.value }}
                                                  </a></template
                                                >
                                                <template v-else>
                                                  {{ column.value }}
                                                </template>
                                              </template>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td height="20"></td>
                                  </tr>
                                </tbody>
                              </template>
                            </table>
                          </div>
                        </draggable>
                      </td>
                    </tr>
                    <tr>
                      <td height="25"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import tblocks from "./json/blocks.json"; //  list of typical blocks
import draggable from "vuedraggable";
import JSSoup from "jssoup";
// предупреждение о перезагрузке

export default {
  props: ["types", "edit", "dataedit", "code", "id"],
  data() {
    return {
      // alert
      alertTime: 3,
      alertDown: 0,
      alertShow: false,
      alertMessage: "",
      alertStyle: "danger",
      // data of block
      typeID: 1,
      name: null,
      section: null,
      // main data
      blocks: tblocks.blocks, // all blocks
      inputs: tblocks.inputs,
      blockName: null, // name of template
      blockContainer: null, // list of actual container block
      blockHTML: [], // html of block with data
      blockData: null,
      blockID: null,
      blockELS: [],
      isEdit: false,
      blockList: [],
      blockSaveIndex: null,

      // end main data
      // collage data
      isCollage: false,
      collageMaxStep: 3,
      collageImages: [],
      collageCurrentStep: 1,
      collagePattern: [],

      // end collage data
      // typical blocks
      // end typical blocks
      // styles
    };
  },
  created() {},
  destroyed() {
    console.log("destroy");
  },
  mounted() {
    //   console.log(this.firm);
    if (this.edit) {
      let info = this.dataedit[0];
      this.typeID = info.type_id;
      this.name = info.name;
      this.section = info.section;
      let data = this.dataedit[0].data;
      data.forEach((element) => {
        this.blockList.push({
          name: element.name, // name of template
          html: JSON.parse(element.html_edit),
          edit: JSON.parse(element.data), // data of els
          pattern: JSON.parse(element.html_edit),
        });
      });
    }
  },
  methods: {
    uploadCollagePattern() {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("pattern", file);
      axios
        .post("/" + this.code + "/block/pattern", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          var html = this.parseHTML(response.data);
          // console.log(soup);
          console.log(response.data);
          this.collagePattern = html;
          this.collageCurrentStep++;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upload(files) {
      let formData = new FormData();

      [...files].forEach((element, index) => {
        if (element) {
          formData.append("img[" + index + "]", element);
          formData.append("names[" + index + "]", element.name);
        }
      });
      return axios.post("/" + this.code + "/block/img", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    uploadCollageImages() {
      console.log("work");

      var files = event.target.files;
      var self = this;
      this.upload(files).then((response) => {
        self.collageImages = response.data;
        console.log(self.collageImages);
        this.insertImagegs();
        console.log("work");
      });
    },
    insertImagegs() {
      let mail = this.collagePattern;
      var self = this;
      var temp = null;
      var tempBlockData = {};
      this.blockData = {};
      console.log(this.collageImages);
      mail.forEach((element, index) => {
        self.blockHTML.push([]);
        element.forEach((current) => {
          temp = Object.assign({}, current);
          let img = temp.img.src.replace("images/", "");
          let el = temp.value;
          console.log(self.collageImages[img]);
          if (self.collageImages[img]) {
            temp.value = temp.value.replace(el, self.collageImages[img]);
            self.$set(self.blockData, el, {
              img: temp.value,
              alt: null,
              link: null,
            });
            self.blockHTML[index].push(temp);
          } else {
            temp.value = temp.value.replace(el, null);
            self.$set(self.blockData, el, {
              img: temp.value,
              alt: null,
              link: null,
            });
            self.blockHTML[index].push(temp);
          }
        });
      });

      // this.selectBlock["el22"].data.img =
      //   "blob:http://localhost:3000/86c302e2-1cf6-47a9-9676-8dddb3543662";
      this.collageCurrentStep++;
    },
    imgUpload() {
      const file = event.target.files[0];
      let id = this.blockID;
      // this.blockData[id].img = URL.createObjectURL(file);
      var self = this;
      let formData = new FormData();

      formData.append("img", file);

      axios
        .post("/" + this.code + "/block/img", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.blockData[id].img = response.data;
        })
        .catch((error) => {});
    },
    selectBlock(name) {
      if (name == "collage") {
        this.isCollage = true;
        this.collageCurrentStep = 1;
        this.$refs.collage.show();
      } else {
        let container = this.blocks[name].vision;
        this.$refs.container.show(); // show modal with container
        this.blockContainer = container; // container block
        this.getELS();
      }
      this.blockName = name; // name of template
      this.blockID = "el1";
      this.$refs.popover.$emit("close"); // close popover
    },
    insertData(el) {
      // use for insert data to block
      if (this.blockName == "collage") {
        this.toogleCollage();
      } else {
        this.toogleContainer(); // toogle style active
      }

      this.blockID = el;
    },
    replaceData(html) {
      let data = [];
      let name = this.blockName;
      let block = this.blockData;
      html.forEach((element, index) => {
        data.push([]);
        Object.values(element).forEach((td, tdID) => {
          let temp = Object.assign({}, td);
          let elValue = temp.value ?? temp.height; // for spacer
          if (name === "collage") {
            elValue = temp.el;
          }
          let el = block[elValue];
          if (el) {
            if (name === "collage") {
              temp.value = el.img ?? null;
              temp.link = el.link ?? null;
              temp.alt = el.alt ?? null;
            } else {
              temp.height = el.spacer ?? null; // for spacer
              temp.value = el.img ? el.img : el.text ? el.text : el.custom; // with add custom code
              temp.img = el.img ? true : false;
              temp.alt = el.alt ?? null;
              temp.link = el.link ?? null;
            }
          }
          data[index].push(temp);
        });
      });
      return data;
    },
    createBlock() {
      // createBlock
      // if (!this.validationBlock()) {
      //   return false;
      // }
      let name = this.blockName; // block name
      let html = null;
      if (name == "custom") {
        html = this.blockData[this.blockID].custom;
        this.blockHTML = html;
        // return;
      } else if (name == "collage") {
        html = this.collagePattern;
        this.blockHTML = this.replaceData(this.collagePattern);
      } else {
        html = this.blocks[name].mail; // html template
        var self = this;
        this.blockHTML = this.replaceData(html);
      }

      this.blockList.push({
        name: this.blockName, // name of template
        html: this.blockHTML,
        edit: this.blockData, // data of els
        pattern: html,
      });
      this.destroy();
    },
    saveBlock() {
      // save block after edit
      let index = this.blockSaveIndex;
      let html = null;
      let newHTML = null;
      let block = this.blockList[index];
      let data = null;
      if (this.blockName == "custom") {
        newHTML = this.blockData[this.blockID].custom;
      } else if (this.blockName == "collage") {
        html = this.collagePattern;
        newHTML = this.replaceData(html);
        // newHTML = this.replaceData(this.collagePattern);
      } else {
        html = this.blocks[this.blockName].mail;
        newHTML = this.replaceData(html);
      }
      data = this.blockData;

      block.html = newHTML;
      block.edit = data;
      this.destroy();
    },
    editBlock(id) {
      let editBlock = this.blockList[id];
      let name = editBlock.name;
      this.blockName = name;
      if (name == "collage") {
        let container = editBlock.html;
        this.blockContainer = container; // container block
        this.collageCurrentStep = 3;
        this.collagePattern = editBlock.pattern;
        this.isCollage = true;
        this.$refs.collage.show(); // show modal with container
      } else {
        let container = this.blocks[name].vision;
        this.blockContainer = container; // container block
        this.$refs.container.show(); // show modal with container
      }
      this.blockID = "el1";
      this.blockData = editBlock.edit;
      this.isEdit = true;
      this.blockSaveIndex = id;
    },
    deleteBlock(id) {
      let isDelete = confirm("Вы действительно хотите удалить блок? ");
      if (isDelete) this.blockList.splice(id, 1);
    },
    uploadTemplateBlock() {
      // load vision of block to modal
    },
    selectTypeBlock() {
      // select type block // banner/text/spacer
    },
    validationBlock() {
      let isError = false;
      let els = this.blockELS;
      var blocks = this.$refs.toogleContainer; // remove all selectes
      blocks.forEach((block) => {
        let id = block.dataset.el;
        let data = this.blockData[id];
        let length = Object.keys(data).length;
        // if (!data.img || !data.text) {
        //   isError = true;
        // }
        // for (let empty in data) {
        //   if (!data[empty]) {
        //     length--;
        //   }
        // }
        // if (length < 2) {
        //   isError = true;
        //   block.classList.remove("active");
        //   block.classList.add("error");
        // }
      });
      return isError ? false : true;
      // check empty block
    },
    getPattern() {
      // parse html from upload pattern
    },
    getListContainer() {},
    toogleContainer() {
      let toogleContainer = this.$refs.toogleContainer;
      toogleContainer.forEach((element) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
      event.target.classList.toggle("active");
      event.target.classList.remove("error");
    },
    toogleCollage() {
      let toogleCollage = this.$refs.toogleCollage;
      toogleCollage.forEach((element) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
      event.target.parentElement.classList.toggle("active");
      event.target.parentElement.classList.remove("error");
    },
    change() {
      let id = this.blockID;
      let type = this.blockData[id].type;
      let img = this.blockData[id].img;
      let text = this.blockData[id].text;

      if (type == "banner") {
        if (img) {
          if (confirm("Удалить все данные по баннеру и перейти в текстовый редактор?")) {
            this.blockData[id].img = null;
            this.blockData[id].link = null;
            this.blockData[id].alt = null;
            this.blockData[id].type = "text";
          }
        } else {
          this.blockData[id].type = "text";
        }
      } else {
        if (text) {
          if (confirm("Удалить текст и перейти в радактор баннера?")) {
            this.blockData[id].text = null;
            this.blockData[id].link = null;
            this.blockData[id].alt = null;
            this.blockData[id].type = "banner";
          }
        } else {
          this.blockData[id].type = "banner";
        }
      }
    },
    getELS() {
      // get insert data
      let container = this.blockContainer;
      this.blockData = {};
      container.forEach((element) => {
        let content = element.content;
        content.forEach((block) => {
          this.blockELS.push(block.el); // list of insert blocks
          // init object for every block
          this.$set(this.blockData, block.el, {
            type: "banner",
            text: null,
            img: null,
            link: null,
            spacer: null,
            alt: null,
          });
        });
      });
    },
    closeEvent() {
      this.destroy();
    },
    parseHTML(html) {
      let soup = new JSSoup(html);
      let trs = soup.findAll("tr");
      let iterator = 1;
      let data = [];
      trs.forEach((tr, index) => {
        let tds = tr.findAll("td");
        let tdList = [];
        tds.forEach((td) => {
          let img = td.nextElement;
          let attr = img.attrs;

          tdList.push({
            width: td.attrs.width ?? null,
            height: td.attrs.height ?? null,
            align: td.attrs.align ?? null,
            valign: td.attrs.valign ?? null,
            value: "el" + iterator,
            el: "el" + iterator,
            img: {
              src: attr.src ?? null,
              width: attr.width ?? null,
              height: attr.height ?? null,
              align: attr.align ?? null,
              valign: attr.valign ?? null,
            },
            colspan: td.attrs.colspan ?? null,
            rowspan: td.attrs.rowspan ?? null,
          });
          if (attr.width > 1) iterator++;
        });

        data.push(tdList);
      });
      return data;
    },
    destroy() {
      this.blockName = null;
      this.blockContainer = null;
      this.blockELS = [];
      this.blockHTML = [];
      this.blockID = null;
      this.blockData = null;

      this.isEdit = false;
      this.blockSaveIndex = null;

      this.isCollage = false;
      this.collageCurrentStep = 1;
      this.collageImages = [];
      this.collagePattern = [];
      this.$refs.container.hide();
      this.$refs.collage.hide();

      // clear data about block
    },
    alertLive(time) {
      this.alertDown = time;
    },
    showAlert(message, style = "danger") {
      this.alertDown = this.alertTime;
      this.alertMessage = message;
      this.alertStyle = style;
    },
    getHTML() {
      var blocks = this.$refs.readyBlock;
      let list = this.blockList;

      if (!this.name && !this.section) {
        this.showAlert("Не заполнено название блока или секции !");
        return false;
      } else if (!blocks) {
        this.showAlert("Серьезно? Вы не создали ни одного блока???????");
        return false;
      }
      // send data

      var data = [];

      blocks.forEach((block, id) => {
        if (list[id].name == "custom") {
          data.push({
            name: list[id].name,
            html: list[id].html,
            edit: JSON.stringify(list[id].edit),
            pattern: JSON.stringify(list[id].pattern),
            html_edit: JSON.stringify(list[id].html),
          });
        } else {
          data.push({
            name: list[id].name,
            html: block.innerHTML,
            edit: JSON.stringify(list[id].edit),
            pattern: JSON.stringify(list[id].pattern),
            html_edit: JSON.stringify(list[id].html),
          });
        }
      });
      let formData = new FormData();

      formData.append("data", JSON.stringify(data));
      console.log(this.edit);
      if (this.edit) {
        formData.append("edit", this.id);
      }
      formData.append("code", this.code);
      formData.append(
        "block",
        JSON.stringify({
          name: this.name,
          section: this.section,
          type: this.typeID,
        })
      );
      var errorStatus = false;
      var returnID = null;
      axios
        .post("/" + this.code + "/block/save", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.showAlert("Блок успешно создан", "success");
          } else {
            this.showAlert("Не удалось создать блок !" + response.statusText, "warning");
            errorStatus = true;
          }
          returnID = response.data;
          if (!errorStatus) {
            this.redirect(response.data);
          }
        })
        .catch((error) => {
          this.showAlert("Не удалось создать блок по техническим причинам");
          errorStatus = true;

          console.log(error);
        });
      //
    },
    redirect($id) {
      window.location.href = "/" + this.code + "/block/edit/" + $id;
    },
  },
  computed: {},
};
</script>

<style>
.modal-body {
  background-color: #464655 !important;
  color: #fff;
}
.hover-blocks:hover {
  background: green;
}
.js-add-mail-block {
  color: black;
}

.popover {
  max-width: 30vw;
  color: black;
}

.bg-white {
  background-color: #fff !important;
  color: #212529;
}

.js-mail-block-el {
  width: 100%;
  cursor: pointer;
}

.modal .js-mail-block-el:hover,
.modal .js-mail-block-el.active {
  background: rgba(49, 190, 114, 0.5);
}

.modal .js-mail-block-el.error {
  background: rgba(252, 107, 107, 0.5);
}

.popover.bs-popover-top .arrow:after {
  border-top-color: #464655;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #464655;
}

.js-wrap-table-block:not(:hover) .edit-table-block {
  opacity: 0;
}

.edit-table-block {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: rgba(70, 70, 85, 0.8);
}

.js-up-table-block,
.js-down-table-block {
  width: 43px;
  height: 43px;
}

.empty-img-danger {
  background-color: #fc6b6b;
}

.modal.show .empty-img-danger img,
.modal.show .js-collage-el.active img,
.modal.show .js-collage-el:hover img {
  opacity: 0.6;
}
</style>
