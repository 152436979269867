<template>
  <div class="row col-12 mx-auto">
    <b-form-select v-model="selected" :options="options"></b-form-select>
    <div class="row col-12 mx-1 px-0 pt-4">
      <div class="col-12  bg-success text-light text-center">Текстовые блоки </div>
    </div>
    <div class="row mx-1 px-0 pt-4">
      <div class="col md-6 px-0">
        <b-card
          img-src="https://picsum.photos/600/300/?image=25"
          img-alt="Image"
          img-height="200"
          img-top
          tag="article"
          style="max-width: 30rem"
          class="mb-6 px-0"
        >
          <h5 class="text-success py-2">Менеджер</h5>
          <a
            v-b-toggle="'collapse-2'"
            class="m-1 text-dark pointer cursor-pointer"
            style="font-size: 14px; font-family: Roboto"
            >Toggle Collapse</a
          >

          <!-- Element to collapse -->
          <b-collapse id="collapse-2" class="py-2 px-1">
            <p><b>Блок используется в письмах:</b></p>
            <ul class="block-uses2">
              <li><a href="#" class="text-dark"> ХЗ ДЕ</a></li>
            </ul>
            <p><b>Переменные:</b></p>
            <ul class="block-uses2">
              <li v-html="'test'"></li>
            </ul>
          </b-collapse>
        </b-card>
      </div>
      <label>&nbsp;&nbsp;</label>
      <div class="col md-6 px-0">
        <b-card
          img-src="https://picsum.photos/600/300/?image=25"
          img-alt="Image"
          img-height="200"
          img-top
          tag="article"
          style="max-width: 30rem"
          class="mb-6 px-0"
        >
          <h5 class="text-success py-2">Менеджер</h5>
          <a
            v-b-toggle="'collapse-3'"
            class="m-1 text-dark pointer cursor-pointer"
            style="font-size: 14px; font-family: Roboto"
            >Toggle Collapse</a
          >

          <!-- Element to collapse -->
          <b-collapse id="collapse-3" class="py-2 px-1">
            <p><b>Блок используется в письмах:</b></p>
            <ul class="block-uses2">
              <li><a href="#" class="text-dark"> ХЗ ДЕ</a></li>
            </ul>
            <p><b>Переменные:</b></p>
            <ul class="block-uses2">
              <li v-html="'test'"></li>
            </ul>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div class="row mx-1 px-0 pt-4">
      <div class="col md-6 px-0">
        <b-card
          img-src="https://picsum.photos/600/300/?image=25"
          img-alt="Image"
          img-height="200"
          img-top
          tag="article"
          style="max-width: 30rem"
          class="mb-6 px-0"
        >
          <h5 class="text-success py-2">Менеджер</h5>
          <a
            v-b-toggle="'collapse-4'"
            class="m-1 text-dark pointer cursor-pointer"
            style="font-size: 14px; font-family: Roboto"
            >Toggle Collapse</a
          >

          <!-- Element to collapse -->
          <b-collapse id="collapse-4" class="py-2 px-1">
            <p><b>Блок используется в письмах:</b></p>
            <ul class="block-uses2">
              <li><a href="#" class="text-dark"> ХЗ ДЕ</a></li>
            </ul>
            <p><b>Переменные:</b></p>
            <ul class="block-uses2">
              <li v-html="'test'"></li>
            </ul>
          </b-collapse>
        </b-card>
      </div>
      <label>&nbsp;&nbsp;</label>
      <div class="col md-6 px-0">
        <b-card
          img-src="https://picsum.photos/600/300/?image=25"
          img-alt="Image"
          img-height="200"
          img-top
          tag="article"
          style="max-width: 30rem"
          class="mb-6 px-0"
        >
          <h5 class="text-success py-2">Менеджер</h5>
          <a
            v-b-toggle="'collapse-5'"
            class="m-1 text-dark pointer cursor-pointer"
            style="font-size: 14px; font-family: Roboto"
            >Toggle Collapse</a
          >

          <!-- Element to collapse -->
          <b-collapse id="collapse-5" class="py-2 px-1">
            <p><b>Блок используется в письмах:</b></p>
            <ul class="block-uses2">
              <li><a href="#" class="text-dark"> ХЗ ДЕ</a></li>
            </ul>
            <p><b>Переменные:</b></p>
            <ul class="block-uses2">
              <li v-html="'test'"></li>
            </ul>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["types"],
  mounted() {
    let types = this.types;
    types.forEach((type) => {
      this.options.push({ value: type.id, text: type.name });
    });
    console.log(this.types);
  },
  data() {
    return {
      selected: 1,
      options: [],
    };
  },
};
</script>
<style scoped>
.block-list {
  margin: 0px 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.card-body {
  padding: 5px;
}

.block-uses2 {
  font-size: 16px;
  line-height: 24px;
  /* or 24px */
  text-decoration: none;
  text-decoration-line: underline;
  padding-left: 0;
  list-style: none;
}
</style>