<template>
  <div>
    <b-alert
      :show="alertDown"
      dismissible
      :variant="alertStyle"
      @dismissed="alertDown = 0"
      @dismiss-count-down="alertLive"
    >
      <p>{{ alertMessage }}</p>
      <b-progress
        :variant="alertStyle"
        :max="alertTime"
        :value="alertDown"
        height="10px"
      ></b-progress>
    </b-alert>
    <div>
      <div class="form-group">
        <label for="name">Название письма</label>
        <input
          type="text"
          class="form-control"
          id="name"
          placeholder="Автоматическое создание ЛК"
          required
          autocomplete="off"
          v-model="name"
        />
      </div>
      <div class="form-group">
        <label for="name">Код письма</label>
        <input
          type="text"
          class="form-control"
          id="name"
          placeholder="pay"
          required
          autocomplete="off"
          v-model="codeName"
        />
      </div>
      <div class="form-group">
        <label for="name">Title - название рассылки</label>
        <input
          type="text"
          class="form-control"
          id="title"
          placeholder="После заказа создан ЛК, автор. для отслеживания..."
          required
          autocomplete="off"
          v-model="title"
        />
      </div>
      <div class="form-group">
        <label for="blockID">Тип письма</label>
        <select
          class="form-control"
          id="blockID"
          ref="blockID"
          v-model="typeMail"
        >
          <option
            v-for="(category, index) in categories"
            :key="index"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="name">Preheader</label>
        <input
          type="text"
          class="form-control"
          id="nameBlock"
          placeholder="Заполните данные в ЛК"
          required
          autocomplete="off"
          v-model="preheader"
        />
      </div>

      <div class="form-group py-0">
        <label for="name">UTM метки</label>
      </div>
      <div class="jumbotron jumbotron-fluid py-3">
        <div class="container">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputCity">utm_source=</label>
              <input
                type="text"
                class="form-control"
                id="inputCity"
                placeholder="Email_auto"
                v-model="utm.utm_source"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inputCity">utm_medium=</label>
              <input
                type="text"
                class="form-control"
                id="inputCity"
                placeholder="email"
                v-model="utm.utm_medium"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inputCity">utm_campaign=</label>
              <input
                type="text"
                class="form-control"
                id="inputCity"
                placeholder="beta-pro-send"
                v-model="utm.utm_campaign"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputCity">utm_content=</label>
              <input
                type="text"
                class="form-control"
                id="inputCity"
                placeholder="{manager_name}"
                v-model="utm.utm_content"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inputCity">utm_term=</label>
              <input
                type="text"
                class="form-control"
                id="inputCity"
                placeholder="{order_id}"
                v-model="utm.utm_term"
              />
            </div>
             <div class="form-group col-md-4">
              <label for="inputCity">GET=</label>
              <input
                type="text"
                class="form-control"
                id="inputCity"
                placeholder="test=123&promo=favsla"
                v-model="utm.get"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12 px-0 mx-0">
      <div class="col-12" id="mail-code">
        <table
          width="100%"
          border="0"
          cellpadding="0"
          cellspacing="0"
          style="border: none; border-collapse: collapse; border-spacing: 0"
        >
          <tbody>
            <tr>
              <td align="center" valign="top">
                <table
                  width="660"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  style="
                    border: none;
                    border-collapse: collapse;
                    border-spacing: 0;
                  "
                >
                  <tbody>
                    <tr>
                      <td height="25"></td>
                    </tr>
                    <tr>
                      <!-- сюда вставляются сгенерированные блоки -->
                      <td align="center" valign="top" id="js-table-wrapper">
                        <div class="position-relative js-wrap-table-block py-2">
                          <table
                            width="660"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              border: none;
                              border-collapse: collapse;
                              border-spacing: 0;
                            "
                            class="js-table-block"
                            ref="readyBlock"
                            v-html="header"
                          ></table>
                        </div>
                        <draggable v-model="blockList">
                          <div
                            v-for="(item, index) in blockList"
                            :key="index"
                            class="position-relative js-wrap-table-block py-2"
                          >
                            <div
                              class="row m-0 py-2 justify-content-center align-items-center position-absolute edit-table-block"
                            >
                              <div class="col-md-4"></div>
                              <div class="col-md-4 px-0">
                                <a :href="'/' + prefix + '/block/edit/' + item.section" 
                                  target="_blank"
                                  class="btn btn-light col-12 js-edit-created-mail-block"
                                  data-edit-id="6"
                                >
                                  редактировать
                                </a>
                              </div>
                              <div
                                class="col-md-4 row px-0 mx-0 justify-content-end h-100"
                              >
                                <div
                                  class="row justify-content-end px-0 mx-0 col-auto align-self-center"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-type-close js-del-table-block mr-2"
                                    @click="deleteBlock(index)"
                                  ></button>
                                </div>
                              </div>
                            </div>
                            <table
                              width="660"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                border: none;
                                border-collapse: collapse;
                                border-spacing: 0;
                              "
                              class="js-table-block"
                              ref="readyBlock"
                            >
                              <tbody
                                v-for="(html, id) in item.html"
                                :key="id"
                                v-html="html"
                              ></tbody>
                            </table>
                          </div>
                          <div
                            class="position-relative js-wrap-table-block py-2"
                          >
                            <table
                              width="660"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="
                                border: none;
                                border-collapse: collapse;
                                border-spacing: 0;
                              "
                              class="js-table-block"
                              ref="readyBlock"
                              v-html="footer"
                            ></table>
                          </div>
                        </draggable>
                      </td>
                    </tr>
                    <tr>
                      <td height="25"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <b-button id="show-blocks">Добавить существующий блок</b-button>
    <button
      id="getHtml"
      type="submit"
      class="btn btn-success float-right"
      @click="getHTML"
    >
      Сохранить письмо
    </button>
    <b-popover
      ref="popover"
      target="show-blocks"
      triggers="click"
      placement="topright"
    >
      <div class="row col-12 mx-0 py-2 px-0 bg-gray6">
        <div class="col-12 mb-2">
          <div class="col-12 text-center bg-gray1 text-white py-1">Блоки</div>
        </div>
        <div class="col-6 mt-3" v-for="(type, index) in types" :key="index">
          <div
            class="col-12 row justify-content-center mx-0 text-center bg-white cursor-pointer p-2"
          >
            <div class="col-12">
              <div
                class="col-12 border rounded js-mail-block-el border-success border-dashed py-3"
                @click="selectTypeBlock(index)"
              >
                {{ type.name }}
              </div>
            </div>
          </div>
          <!-- <div v-html="block.short"></div> -->
        </div>
      </div>
    </b-popover>
    <b-modal ref="blocks" id="modal-center" centered class="bg-gray6" size="lg">
      <div class="row justify-content-center mx-0">
        <div
          class="col-3 mt-3"
          v-for="(block, index) in listBlocks"
          :key="index"
          @click="toogleActive(block.id)"
        >
          <b-card
            img-src="https://vectorified.com/images/blue-mail-icon-34.png"
            img-alt="Image"
            img-height="150"
            img-top
            style="max-width: 30rem; cursor: pointer"
            class="mb-6 px-1 py-1"
            ref="block"
          >
            <b-card-title class="text-success pt-2 text-center">{{
              block.name
            }}</b-card-title>
          </b-card>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            size="md"
            class="float-right"
            @click="closeBlock"
            >Отменить</b-button
          >
          <b-button
            variant="success"
            size="md"
            class="float-left"
            @click="addBlock"
            >Добавить</b-button
          >
        </div>
      </template>
      <!-- end Modal -->
    </b-modal>
    <!-- <div
      ref="capture"
      style="border: 1px solid black"
      v-html="test"
    ></div>
      <div class="col-2" style="width: 300px; height: 300px">
        <img :src="output"/>

      </div> -->
  </div>
</template>

<script>
// import VueHtml2Canvas from 'vue-html2canvas';
import draggable from "vuedraggable";

export default {
  props: [
    "types",
    "blocks",
    "categories",
    "prefix",
    "id",
    "init",
    "mail",
    "edit",
  ],
  data() {
    return {
      // alert
      alertTime: 3,
      alertDown: 0,
      alertShow: false,
      alertMessage: "",
      alertStyle: "danger",

      // main content
      name: null,
      title: null,
      typeMail: 1,
      preheader: null,
      codeName: null,

      // utm
      utm: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        utm_term: null,
        get: null,
      },

      // end utm

      selectType: null,
      listBlocks: null,
      test: null,
      img: "<h3>213</h3>",
      output: null,
      header: null,
      footer: null,
      selectBlockID: null,
      blockList: [],
      html: "",
    };
  },
  mounted() {
    // console.log(this.test);
    // console.log("ye");
    console.log(this);
    // console.log(this.mail);
    // if (this.init) {
    // }
    if (this.edit) {
      let blocks = this.init;
      let temp = [];
      blocks.forEach((element) => {
        // console.log(element[0].id);
        let section = element[0].id;
        let data = element[0].data;
        temp = [];
        data.forEach((source) => {
          temp.push(source.html);
        });
        this.blockList.push({ html: temp, section: section });
      });
      this.name = this.mail.name;
      this.title = this.mail.title;
      this.typeMail = this.mail.type_id;
      this.preheader = this.mail.preheader;
      this.codeName = this.mail.code_name;
      this.utm = JSON.parse(this.mail.utm);
    }

    // console.log(blocks);
    this.header = this.blocks[0].data[0].html;
    this.footer = this.blocks[1].data[0].html;
    this.listBlocks = this.types[0].mails;
    // this.print();
  },
  methods: {
    selectTypeBlock(id) {
      this.selectType = id;
      this.listBlocks = this.types[id].mails;
      this.$refs.blocks.show();
      this.$refs.popover.$emit("close");
    },
    toogleActive(index) {
      console.log(index);
      let toogleCollage = this.$refs.block;
      toogleCollage.forEach((element) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
      event.target.parentElement.classList.toggle("active");
      this.selectBlockID = index;
    },
    closeBlock() {
      this.$refs.blocks.hide();
    },
    getElement() {
      let blocks = this.blocks;
    },
    getUTM(utm) {
      let result = JSON.parse(utm);
      for (var name in result) {
        let index = result[index];
        this.$set(this.utm, name, index);
      }
    },
    getHTML() {
      let blocks = this.blockList;
      var data = [];
      let uri = this.prefix + "/mail/save";
      if (blocks.length < 1) {
        this.showAlert("Сударь, а не желаете ли Вы добавить блок ?");
        window.scrollTo(0, 0);
        return false;
      } else if (
        !this.name &&
        !this.title &&
        !this.preheader &&
        !this.codeName
      ) {
        this.showAlert(
          "Сударь, а не желаете ли Вы заполнить все данные о письме ?"
        );
        window.scrollTo(0, 0);
        return false;
      }
      blocks.forEach((block, id) => {
        data.push({
          name: block.section,
        });
      });
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("title", this.title);
      formData.append("type", this.typeMail);
      formData.append("preheader", this.preheader);
      formData.append("code", this.prefix);
      formData.append("code_name", this.codeName);
      formData.append("utm", JSON.stringify(this.utm));

      // formData.append("utm", JSON.stringify(data));
      if (this.edit) {
        formData.append("mail_edit_id", this.id);
      }
      formData.append("sections", JSON.stringify(data));
      // formData.append(
      //   "block",
      //   JSON.stringify({
      //     name: this.name,
      //     section: this.section,
      //     type: this.typeID,
      //   })
      // );

      axios
        .post("/" + this.prefix + "/mail/save", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.showAlert("Блок успешно создан", "success");
            window.scrollTo(0, 0);
          } else {
            this.showAlert(
              "Не удалось создать блок !" + response.statusText,
              "warning"
            );
            window.scrollTo(0, 0);
          }
          this.html = response.data;
          console.log(response);
          this.redirect(response.data);
        })
        .catch((error) => {
          this.showAlert("Не удалось создать блок по техническим причинам");
          window.scrollTo(0, 0);

          console.log(error);
        });
      //
    },
    alertLive(time) {
      this.alertDown = time;
    },
    redirect($id) {
      window.location.href = "/" + this.prefix + "/mail/edit/" + $id;
    },
    showAlert(message, style = "danger") {
      this.alertDown = this.alertTime;
      this.alertMessage = message;
      this.alertStyle = style;
    },
    deleteBlock(id) {
      let isDelete = confirm("Вы действительно хотите удалить блок? ");
      if (isDelete) this.blockList.splice(id, 1);
    },
    addBlock() {
      let type = this.selectType;
      let id = this.selectBlockID;
      let generate = [];
      console.log(id); // let blocks = this.blocks[id].data;
      console.log(this.blocks);
      let blocks = this.blocks;
      var block = null;
      var section = null;

      blocks.forEach((element, index) => {
        console.log(element);
        if (element.id == id) {
          block = element.data;
          section = id;
        }
      });
      let temp = [];
      console.log(block);
      block.forEach((element, index) => {
        temp.push(element.html);
      });
      this.blockList.push({ html: temp, section: section });
      console.log(this.blockList);
      console.log("add");
    },
    // async print() {
    //   const el = this.$refs.capture;

    //   const options = {
    //     type: "dataURL",
    //     width: "200",
    //     height: "200"
    //   };
    //   let sajd = null;
    //   console.log("dermo");
    //   this.output = await this.$html2canvas(el, options);
    //   console.log(this.output);
    //   console.log('dermo end');
    // },
  },
};
</script>

<style scoped>
.stylebody {
  padding: 0px;
}
.card-body {
  padding: 0px;
  margin: 0px;
}
.card.active {
  background: palegreen;
}
</style>